import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { ChickenMessage } from 'components/ChickenMessage'
import { SchedulePickupConfirmationForm } from 'forms/SchedulePickupConfirmationForm'
import { SchedulePickupRequestParams } from 'types'
import { Spinner } from 'components/Spinner'
import useModal from 'hooks/useModal'
import { useOrder } from 'contexts/orderContext'

import * as helpers from './helpers'

export const SchedulePickupPage = () => {
  const {
    state: { order, loading },
  } = useOrder()
  const { isShowing, toggle } = useModal()

  const [error, setError] = React.useState('')
  const [schedulePickupParams, setSchedulePickupParams] = React.useState<
    SchedulePickupRequestParams | undefined
  >()
  const [schedulingPickup, setSchedulingPickup] = React.useState(false)

  const navigate = useNavigate()

  if (loading) {
    return <Spinner />
  }

  if (!order) {
    throw new Error('Problem loading order data')
  }

  return (
    <>
      <ChickenMessage
        loading={schedulingPickup}
        onCancel={toggle}
        onConfirm={async () => {
          setSchedulingPickup(true)

          await helpers.handleConfirmSchedulePickup(
            schedulePickupParams as SchedulePickupRequestParams,
            navigate,
            toggle,
            setError,
          )

          setSchedulingPickup(false)
        }}
        show={isShowing}
        title="Continue?"
      >
        Are you sure you want to schedule a pickup at this address for this package?
      </ChickenMessage>

      <SchedulePickupConfirmationForm
        error={error}
        initialValues={{
          ...order.location.address,
          ...order.location.contact,
          addressType: 'default',
          company: order.location.company,
          earliestTime: '0900',
          latestTime: '1700',
          pickupDate: order.settings.pickupAvailability[0].date,
          pointOfPickup: 'Front door',
          specialInstructions: '',
        }}
        onSubmit={(values, setSubmitting) => {
          helpers.handleSchedulePickupFormSubmit(
            values,
            order.securityCode,
            order.trackingNumbers ?? [],
            setSchedulePickupParams,
          )

          setSubmitting(false)

          toggle()
        }}
        pickupAvailability={order.settings.pickupAvailability.map(d => ({
          label: d.displayDate,
          value: d.date,
        }))}
        states={order.settings.states.map(s => ({
          label: s.name,
          value: s.state,
        }))}
        trackingNumbers={order.trackingNumbers ?? []}
      />
    </>
  )
}
