import { NavigateFunction } from 'react-router-dom'

import { api } from 'api'
import { FormValues } from 'forms/SchedulePickupConfirmationForm'
import { SchedulePickupRequestParams, ShipType } from 'types'
import { routes } from 'routes'

export const handleConfirmSchedulePickup = async (
  params: SchedulePickupRequestParams,
  navigate: NavigateFunction,
  toggle: () => void,
  setError: (error: string) => void,
): Promise<void> => {
  try {
    const pickupRequestNumber = await api.order.schedulePickup(params)
    toggle()
    navigate(routes.confirmSchedulePickup, { state: { ...params, pickupRequestNumber } })
  } catch {
    toggle()
    setError(
      'Problem scheduling pickup. Please try again, or contact us for assistance using the Contact Us link at the top of this page.',
    )
  }
}

export const handleSchedulePickupFormSubmit = (
  values: FormValues,
  securityCode: string,
  trackingNumbers: string[],
  setSchedulePickupParams: (params: SchedulePickupRequestParams) => void,
) => {
  setSchedulePickupParams({
    address: {
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      company: values.company,
      country: values.country,
      email: values.email,
      lname: values.lastName,
      name: values.firstName,
      phone: values.phone,
      postal_code: values.postalCode,
      state_province: values.state,
    },
    security_code: securityCode,
    pickup: {
      earliest_time: values.earliestTime,
      latest_time: values.latestTime,
      pickup_date: values.pickupDate,
      point_of_pickup: values.pointOfPickup,
      ship_type: ShipType.schedule,
    },
    special_instructions: values.specialInstructions ?? '',
    tracking_numbers: trackingNumbers,
  })
}
