import { endpoints } from 'api/endpoints'
import { http } from 'api/http'
import {
  CreateLabelsRequestParams,
  EmailLabelRequestParams,
  EmailLabelsRequestParams,
  GetLabelRequestParams,
  GetLabelResponse,
  GetOrderRequestParams,
  GetOrderResponse,
  Label,
  Location,
  Order,
  SchedulePickupRequestParams,
  ShipType,
  UpdateOrderResponse,
} from 'types'

const convertGetOrderResponseToOrder = ({
  ars_number,
  job,
  label_details,
  security_code,
  label_count,
  settings,
}: GetOrderResponse): Order => ({
  arsNumber: ars_number,
  jobId: job.job_id,
  labels: label_details.map((label, index) => ({
    assetTypeId: Number(label.asset_type),
    labelNumber: index + 1,
    selected: false,
    serialNumber: label.asset_tag,
    status: label.ship_status,
    trackingNumber: label.tracking_number,
  })),
  labelCount: label_count,
  location: {
    address: {
      address1: job.address1,
      address2: job.address2,
      city: job.city,
      country: job.country,
      postalCode: job.zip,
      state: job.state,
    },
    company: job.company,
    contact: {
      email: job.user_email,
      firstName: job.name,
      lastName: job.lname,
      phone: job.phone,
    },
  },
  securityCode: security_code,
  settings: {
    assetTypes: settings.asset_types,
    pickupAvailability: settings.pickupAvailability.dates.map(date => ({
      date: date.date,
      displayDate: date.display_date,
    })),
    states: settings.states,
  },
  status: job.status_code,
})

export const order = {
  createNewLabels: async (params: CreateLabelsRequestParams): Promise<void> => {
    await http.post(endpoints.order, {
      action: 'create_new_labels',
      security_code: params.securityCode,
      labels: params.labels.map(label => ({
        asset_type: label.assetTypeId,
        label_number: label.labelNumber,
        serial_number: label.serialNumber,
      })),
      pickup: {
        earliest_time: params.shipType === ShipType.schedule ? params.earliestTime : undefined,
        latest_time: params.shipType === ShipType.schedule ? params.latestTime : undefined,
        pickup_date: params.shipType === ShipType.schedule ? params.pickupDate : undefined,
        ship_type: params.shipType,
      },
    })
  },

  emailLabel: async (params: EmailLabelRequestParams): Promise<void> => {
    await http.post(endpoints.order, {
      action: 'email_label',
      security_code: params.securityCode,
      email: params.email,
      label_numbers: params.labelNumbers,
    })
  },

  emailNewLabels: async (params: EmailLabelsRequestParams): Promise<void> => {
    await http.post(endpoints.order, {
      action: 'email_new_labels',
      security_code: params.securityCode,
      email: params.email,
      labels: params.labels.map(label => ({
        asset_type: label.assetTypeId,
        label_number: label.labelNumber,
        serial_number: label.serialNumber,
      })),
      pickup: {
        earliest_time: params.shipType === ShipType.schedule ? params.earliestTime : undefined,
        latest_time: params.shipType === ShipType.schedule ? params.latestTime : undefined,
        pickup_date: params.shipType === ShipType.schedule ? params.pickupDate : undefined,
        ship_type: params.shipType,
      },
    })
  },

  getLabel: async (params: GetLabelRequestParams): Promise<string> => {
    const { data } = await http.post<GetLabelResponse>(endpoints.order, {
      action: 'get_label',
      security_code: params.securityCode,
      label_numbers: params.labelNumbers,
    })

    return data.pdfpath
  },

  getOrder: async (params: GetOrderRequestParams): Promise<Order> => {
    const { data } = await http.get<GetOrderResponse>(endpoints.order, {
      params: {
        action: 'get',
        security_code: params.securityCode,
      },
    })

    return convertGetOrderResponseToOrder(data)
  },

  getOrderByTrackingNumber: async (trackingNumber: string): Promise<Order> => {
    const { data } = await http.get<GetOrderResponse>(endpoints.order, {
      params: {
        action: 'get_by_tracking_number',
        tracking_number: trackingNumber,
      },
    })

    return { ...convertGetOrderResponseToOrder(data), trackingNumbers: [trackingNumber] }
  },

  saveLabelInfo: async (securityCode: string, labels: Array<Label>): Promise<void> => {
    await http.post(endpoints.order, {
      action: 'save_label_info',
      security_code: securityCode,
      labels: labels.map(label => ({
        asset_type: label.assetTypeId,
        label_number: label.labelNumber,
        serial_number: label.serialNumber,
      })),
    })
  },

  schedulePickup: async (params: SchedulePickupRequestParams): Promise<string> => {
    const { data } = await http.post<{ pickup_request_number: string }>(endpoints.order, {
      action: 'schedule_pickup',
      ...params,
    })

    return data.pickup_request_number
  },

  sendConfirmationEmail: async (
    securityCode: string,
    email: string,
    pickupRequestNumber: string,
  ): Promise<void> => {
    await http.post(endpoints.order, {
      action: 'send_confirmation_email',
      security_code: securityCode,
      email,
      pickup_request_number: pickupRequestNumber,
    })
  },

  update: async (
    securityCode: string,
    location: Location,
  ): Promise<{ addressId: number; validAddress: boolean }> => {
    const { data } = await http.post<UpdateOrderResponse>(endpoints.order, {
      action: 'update',
      security_code: securityCode,
      address: {
        name: location.contact.firstName,
        lname: location.contact.lastName,
        company: location.company,
        phone: location.contact.phone,
        address1: location.address.address1,
        address2: location.address.address2,
        city: location.address.city,
        state_province: location.address.state,
        postal_code: location.address.postalCode,
        country: location.address.country,
        email: location.contact.email,
      },
    })

    return { addressId: data.address.address_id, validAddress: data.valid_address }
  },
}
