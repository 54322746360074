import * as React from 'react'

import { api } from 'api'
import { Label, Location, Order } from 'types'

type Action =
  | { payload: Order; type: 'initialize' }
  | { payload: boolean; type: 'set_loading' }
  | { payload: Label[]; type: 'update_labels' }
  | { payload: Location; type: 'update_order' }
  | { payload: string[]; type: 'update_tracking_numbers' }
export type Dispatch = (action: Action) => void
type State = { loading: boolean; order?: Order }

const OrderContext = React.createContext<{ dispatch: Dispatch; state: State } | undefined>(
  undefined,
)

export const orderReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'initialize':
      if (state.order?.jobId !== action.payload.jobId) {
        return {
          ...state,
          order: action.payload,
        }
      }

      // This feels quite hacky but we need to preserve the selected state
      // of the labels in the case where we navigate to the Confirmation page
      // so that we know which labels to show there.
      return {
        ...state,
        order: {
          ...action.payload,
          labels: action.payload.labels.map((label, index) => ({
            ...label,
            selected: state.order?.labels[index].selected ?? false,
          })),
        },
      }

    case 'set_loading':
      return {
        ...state,
        loading: action.payload,
      }

    case 'update_labels':
      if (state.order) {
        return {
          ...state,
          order: {
            ...state.order,
            labels: action.payload,
          },
        }
      }

      return state

    case 'update_order':
      if (state.order) {
        return {
          ...state,
          order: {
            ...state.order,
            location: action.payload,
          },
        }
      }

      return state

    case 'update_tracking_numbers':
      if (state.order) {
        return {
          ...state,
          order: {
            ...state.order,
            trackingNumbers: action.payload,
          },
        }
      }

      return state

    default:
      throw new Error('Unhandled action type in order reducer')
  }
}

const getOrder = async (
  { securityCode, trackingNumber }: { securityCode?: string; trackingNumber?: string },
  dispatch: Dispatch,
  hideLoader?: boolean,
): Promise<void> => {
  try {
    if (!hideLoader) {
      dispatch({ payload: true, type: 'set_loading' })
    }

    const order = trackingNumber
      ? await api.order.getOrderByTrackingNumber(trackingNumber)
      : await api.order.getOrder({ securityCode })

    dispatch({ payload: order, type: 'initialize' })
  } finally {
    dispatch({ payload: false, type: 'set_loading' })
  }
}

type OrderProviderProps = {
  children: React.ReactNode
}

const initialState: State = {
  loading: true,
  order: undefined,
}

const OrderProvider = ({ children }: OrderProviderProps) => {
  const [state, dispatch] = React.useReducer(orderReducer, initialState)

  // Attempt to load any order data stored in session storage
  React.useEffect(() => {
    dispatch({ payload: true, type: 'set_loading' })

    const savedState = JSON.parse(window.sessionStorage.getItem('state') ?? '{}') as State
    if (savedState.order) {
      dispatch({ payload: savedState.order, type: 'initialize' })
    }

    dispatch({ payload: false, type: 'set_loading' })
  }, [])

  // Update the order data stored in session storage whenever the state
  // is updated
  React.useEffect(() => {
    window.sessionStorage.setItem('state', JSON.stringify(state))
  }, [state])

  const value = { dispatch, state }

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
}

const useOrder = () => {
  const context = React.useContext(OrderContext)

  if (context === undefined) {
    throw new Error('useOrder must be used within an OrderProvider')
  }

  return context
}

export { getOrder, OrderProvider, useOrder }
